import React, {useEffect, useState} from 'react';
import ym from 'react-yandex-metrika';
import {useLocation, useParams, useHistory} from 'react-router-dom';
import {usePaymentInputs} from 'react-payment-inputs';
import '../styles/payment.css';
import axios from '../utils/axios';

const Payment = () => {

  let history = useHistory();
  const qs = require('qs');
  const location = useLocation();
  const {userId} = useParams();

  const {meta, getCardNumberProps, getExpiryDateProps, getCVCProps} = usePaymentInputs(
      {
        errorMessages: {
          emptyCardNumber: 'Введите номер карты',
          invalidCardNumber: 'Некорректный номер карты',
          emptyExpiryDate: 'Введите дату истечения карты',
          monthOutOfRange: 'Месяц истечения карты должен быть от 01 до 12',
          yearOutOfRange: 'Срок действия карты истек',
          dateOutOfRange: 'Срок действия карты истек',
          invalidExpiryDate: 'Некорректная дата истечения карты',
          emptyCVC: 'Введите CVC/CVV код',
          invalidCVC: 'Некорректный CVC/CVV код',
        },
      },
  );

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ym('hit', location.pathname);
    }
  }, [location]);


  const [cardNumber, setCardNumber] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [cvv, setCVV] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
  }, [email, phone]);

  const handleChangeCardNumber = (event) => {
    setCardNumber(event.target.value);
    setError('');
  };

  const handleFocusCardNumber = () => {
    setError('');
  };

  const submitPayment = () => {
    if (!cardNumber) {
      setError('Введите данные карты');
      return;
    }
    if (!phone && !email) {
      setError('Введите email или номер телефона');
      return;
    }
    if ((!meta.error && cardNumber && !error) || (isValidMirCard(cardNumber) && !error)) {
      let data = {
        userId: userId,
        phone: phone,
        email: email,
        cardNumber: cardNumber.replace(/\s/g, ''),
      };

      axios
      .post('/premium/payment/fake-subscription', qs.stringify(data))
      .then((resp) => {
        if (resp.data.status && resp.data.status === 'error') {
          setError('Что-то пошло не так');
        } else {
          history.push('/success');
        }
      }).catch(() => setError('Что-то пошло не так'));
    }

  };

  const isValidMirCard = (cardNumber) => {
    return meta.cardType &&
        meta.cardType.type === 'mir' &&
        meta.erroredInputs.cvc === undefined &&
        meta.erroredInputs.expiryDate === undefined &&
        cardNumber.length === 19;
  };

  let handleChangeCVV = (event) => {
    setCVV(event.target.value);
  };

  let handleCheckbox = () => {};
  let handleEmail = (event) => {
    setEmail(event.target.value);
  };
  let handlePhone = (event) => {
    setPhone(event.target.value);
  };

  return (
      <div>
        <div className="card-form">

          <div className="input-group input-group__first">
            <label>Номер карты</label>
            <input className="input" {...getCardNumberProps(
                {
                  onChange: handleChangeCardNumber,
                  onFocus: handleFocusCardNumber,
                },
            )} placeholder="0000 0000 0000 0000"/>
          </div>

          <div className="input-group input-group__child">
            <label>Действительна до</label>
            <input className="input" {...getExpiryDateProps()} placeholder="12 / 24"/>
          </div>

          <div className="input-group input-group__child">
            <label>CVV/CVC</label>
            <input className="input" {...getCVCProps({onChange: handleChangeCVV})}
                   placeholder=""
                   value={cvv}
                   maxLength={3}/>
          </div>

          <div className="input-group">
            <input type="checkbox"
                   checked={true}
                   onChange={handleCheckbox}/>
                   <label>Запомнить карту</label>
          </div>

          <div className="input-group">
            <input type="email" className="input input_text" onChange={handleEmail} placeholder="Электронная почта"/>
            <p className="email-desc">По указанному адресу мы вышлем информацию о совершенном платеже.</p>
          </div>

          <div className="input-group">
            <input type="tel" className="input input_text" onChange={handlePhone} placeholder="Телефон"/>
          </div>

        </div>

        {meta.isTouched && meta.error && !isValidMirCard(cardNumber) && !error &&
        <span className="error">{meta.error}</span>
        }

        {error && <span className="error">{error}</span>}

        <p className="secure">Данные карты защищены</p>

        <div className="button" onClick={submitPayment}>
          Привязать карту
        </div>
      </div>
  );
};

export default Payment;

import React from 'react';
import './styles/App.css';
import {Route, Switch} from 'react-router-dom';
import Offer from './pages/Offer';
import Payment from './pages/Payment';
import Success from './pages/Success';
import NotFound from './pages/NotFound';

function App() {

  return (
      <div className="App">
        <Switch>
          <Route path="/offer/:userId([0-9]+)" component={Offer} />
          <Route path="/payment/:userId([0-9]+)" component={Payment} />
          <Route path="/success" component={Success} />

          <Route component={NotFound} />
        </Switch>
      </div>
  );
}

export default App;

import axios from "axios";

const DEVELOPMENT_API_BASE_URL = "http://localhost:8081/105/api/";
const PRODUCTION_API_BASE_URL = "https://api.rosfines.ru/105/api/";

let baseUrl = DEVELOPMENT_API_BASE_URL;
let timeout = 10000;

if (process.env.NODE_ENV === 'production') {
  baseUrl = PRODUCTION_API_BASE_URL;
  timeout = 2000;
}

const ax = axios.create({
  baseURL: baseUrl,
  timeout: timeout
});

export default ax;
import React, {useEffect} from 'react';
import ym from 'react-yandex-metrika';
import {useLocation, NavLink, useParams, useHistory} from 'react-router-dom';
import '../styles/offer.css';
import card from '../assets/icons/card_1.svg';
import gas from '../assets/icons/gas.svg';
import map from '../assets/icons/map.svg';
import axios from '../utils/axios';

const Offer = () => {

  let history = useHistory();
  const location = useLocation();
  const {userId} = useParams();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ym('hit', location.pathname);
    }
  }, [location]);

  // check if subscription already exists
  useEffect(() => {
    axios.get("/premium/gazprom-subscription?userId=" + userId)
    .then((response) => {
      if (response.data.response.id) {
        history.push('/success');
      }
    })
    .catch()
  });


  return (
      <div>

        <h1 className="title">
          Заправляйтесь не выходя из машины со скидкой 2,5% на АЗС ПАО «Газпромнефть»
        </h1>

        <div className="benefits">
          <div className="benefit">
            <div className="benefit_icon"><img src={card} alt=""/></div>
            <p className="benefit_text">
              Привяжите карту для быстрой оплаты без комиссии
            </p>
          </div>
          <div className="benefit">
            <div className="benefit_icon"><img src={map} alt=""/></div>
            <p className="benefit_text">
              Выберите нужную вам АЗС
            </p>
          </div>
          <div className="benefit">
            <div className="benefit_icon"><img src={gas} alt=""/></div>
            <p className="benefit_text">
              Оплатите топливо и заправьтесь со скидкой 2,5% от цены, указанной на стелле
            </p>
          </div>
        </div>

        <NavLink className="button" to={'/payment/' + userId}>Привязать карту</NavLink>

      </div>
  );
};

export default Offer;

import React, {useEffect} from 'react';
import ym from 'react-yandex-metrika';

const NotFound = () => {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ym('hit', "/not-found");
    }
  })

  return (
      <div>
        <h1>Страница не найдена</h1>
      </div>
  );
};

export default NotFound;

import React, {useEffect} from 'react';
import ym from 'react-yandex-metrika';
import {useLocation} from 'react-router-dom';
import errorIcon from '../assets/icons/error.svg';
import "../styles/success.css";

const Success = () => {

  let location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ym('hit', location.pathname);
    }
  }, [location]);

  return (
      <div>
        <div className="error-block">
          <img src={errorIcon} className="error-icon" alt="Sorry"/>
        </div>

        <h1 className="sorry-title">Услуга пока недоступна</h1>
        <p className="sub-paragraph">Но мы перечислим вам 500 руб. на указанную карту в течение суток.</p>

        <h2 className="subtitle">Почему?</h2>
        <p>Услуга в данный момент не оказывается.</p>

        <h2 className="subtitle">Зачем мы это делаем?</h2>
        <p>Мы тестируем интерес наших пользователей к данной услуге.<br/>
          Спасибо вам за уделенное время!
        </p>

        <h2 className="subtitle">Остались вопросы?</h2>
        <p>Напишите нам: <a className="link" href="mailto:dmitry.denisov@rosfines.ru">dmitry.denisov@rosfines.ru</a>
        </p>
        <p>Или позвоните: <a className="link" href="tel:+7-953-985-50-16">+7 (953) 985-50-16</a></p>

      </div>
  );
};

export default Success;
